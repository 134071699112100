import {
  Box,
  Button,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core"
import { Visibility } from "@material-ui/icons"
import React from "react"
import Layout from "../components/layout/Layout"
import Image from "../components/simple/Image"
import { useMobile } from "../utils/hooks"

import { makeStyles } from "@material-ui/core"
import { CONTAINER_PADDING } from "../gatsby-theme-material-ui-top-layout/theme"
import TwoColsLayout from "../components/layout/TwoColsLayout"

const Register = () => {
  const mobile = useMobile()

  return (
    <TwoColsLayout
      imageProps={{
        filename: "register_c9kjkj.jpg",
        alt: "",
      }}
    >
      <Grid item container xs={12} md={8} lg={6} spacing={4} justify="center">
        <Grid item xs={12}>
          <Typography variant="h2" align="center">
            Rejestracja
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField label="E-mail" fullWidth />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Hasło"
            fullWidth
            InputProps={{ endAdornment: <Visibility color="action" /> }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Powtórz hasło"
            fullWidth
            InputProps={{ endAdornment: <Visibility color="action" /> }}
          />
        </Grid>

        <Grid item container xs={12} justify="center">
          <Link to="/logowanie" variant="h5">
            zarejestruj
          </Link>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center" variant="h6" gutterBottom>
            Masz już konto
          </Typography>

          <Link to="/logowanie">
            <Typography variant="h6" align="center">
              zaloguj się
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </TwoColsLayout>
  )
}

export default Register
