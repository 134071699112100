import { Box, Grid } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import { useMobile } from "../../utils/hooks"
import Footer from "../Footer"
import Header from "../Header"

import { makeStyles } from "@material-ui/core"
import Image from "../simple/Image"
import { CONTAINER_PADDING } from "../../gatsby-theme-material-ui-top-layout/theme"
import Seo from "../seo"
const HEADER_HEIGHT = 80

const useStyles = makeStyles(theme => ({
  padY: {
    paddingTop: theme.spacing(CONTAINER_PADDING / 2),
    paddingBottom: theme.spacing(CONTAINER_PADDING / 2),
  },
}))

const TwoColsLayout = ({
  children,
  navbarLeftSlot,
  navbarRightSlot,
  leftCol,
  rightCol,
  imageProps = {},
}) => {
  const mobile = useMobile()
  const classes = useStyles()

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <Seo />

      <Header
        slotLeft={navbarLeftSlot}
        slotRight={navbarRightSlot}
        maxHeight={HEADER_HEIGHT}
      />

      <Box
        display="flex"
        flexDirection="column"
        flexGrow={1}
        overflow="hidden"
        paddingTop={`${HEADER_HEIGHT}px`}
      >
        <Grid
          container
          style={mobile ? {} : { maxHeight: "calc(100vh - 80px)" }}
        >
          <Grid item xs={12} md={8} lg={6} style={{ overflow: "hidden" }}>
            <Image
              style={mobile ? {} : { maxHeight: "calc(100vh - 80px)" }}
              aspectRatio={mobile ? 16 / 9 : undefined}
              objectFit="cover"
              {...imageProps}
            />
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
            lg={6}
            container
            alignItems="center"
            justify="center"
            className={classes.padY}
          >
            {children}
          </Grid>
        </Grid>
      </Box>

      <Footer />
    </Box>
  )
}

export default TwoColsLayout
