import React from "react"

import { makeStyles } from "@material-ui/core"
import clsx from "clsx"
import GatsbyImage from "../common/GatsbyImage"
const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    width: "100%",
    height: "auto",
    objectPosition: props => props.objectPosition,
  },
  rootObjectFitCover: {
    objectFit: "cover",
  },
  rootObjectFitContain: {
    objectFit: "contain",
  },
}))

const Image = ({
  className,
  alt = "",
  objectPosition,
  objectFit,
  src,
  filename,
  aspectRatio,
  style,
  imgStyle,
  ...props
}) => {
  const classes = useStyles({ objectPosition })

  if (filename) {
    return (
      <GatsbyImage
        filename={filename}
        aspectRatio={aspectRatio}
        imgStyle={{
          objectFit,
          objectPosition: "50% 50%",
          ...(imgStyle || {}),
        }}
        alt={alt}
        style={style}
      />
    )
  }

  return (
    <img
      className={clsx(
        classes.root,
        {
          [classes.rootObjectFitCover]: objectFit === "cover",
          [classes.rootObjectFitContain]: objectFit === "contain",
        },
        className
      )}
      alt={alt}
      src={src}
      {...props}
    />
  )
}

export default Image
